(function($) {

    $(window).on('load', function() {
        setTimeout(function() {
            $( 'body' ).imagesLoaded( function() {
                $( '.loading-frame' ).fadeOut();
            });
        }, 100 );
    });

})( jQuery );